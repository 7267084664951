import { FC, useCallback, useState } from "react";
import { ResetIcon } from "../../../../../../../../common/components/icons/reset-icon/reset-icon";
import { AdminGridItem } from "../../admin-grid-item";
import { ResetEnvironmentDialog } from "./subcomponents/reset-environment-dialog/reset-environment-dialog";

export const ResetEnvironmentGridItem: FC = () => {

    // state
    const [showDialog, setShowDialog] = useState<boolean>(false)

    // event handlers
    const handleGridItemClick = useCallback(() => {
        setShowDialog(true)
    }, [])

    const handleDialogClose = useCallback(() => {
        setShowDialog(false)
    }, [])

    return (
        <>
            <AdminGridItem
                title="Reset Environment"
                subtitle="Reset all external accounts in the demo environment"
                iconComponent={ResetIcon}
                onClick={handleGridItemClick}
            />
            <ResetEnvironmentDialog
                isOpen={showDialog}
                onClose={handleDialogClose}
            />
        </>
    )
}