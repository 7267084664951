import { FC } from "react";
import { IconSvg } from "../icons.styled";
import { IconProps } from "../icons.types";

export const PieChartIcon: FC<IconProps> = ({
    size,
    color,
}) => {
    return (
        <IconSvg
            size={size}
            color={color}
            viewBox="0 0 576 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M304 240V16.6c0-9 7-16.6 16-16.6C443.7 0 544 100.3 544 224c0 9-7.6 16-16.6 16H304zM32 272C32 150.7 122.1 50.3 239 34.3c9.2-1.3 17 6.1 17 15.4V288L412.5 444.5c6.7 6.7 6.2 17.7-1.5 23.1C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zm526.4 16c9.3 0 16.6 7.8 15.4 17c-7.7 55.9-34.6 105.6-73.9 142.3c-6 5.6-15.4 5.2-21.2-.7L320 288H558.4z"
                fill={color}
            />
        </IconSvg>
    )
}