import { FC } from "react";
import { SpanSkeleton } from "../../../../../../../common/skeletons/skeletons";
import { useDashboardLink } from "./demo-dashboard-nav-link.hooks";
import { NavLinkAnchor } from "./demo-dashboard-nav-link.styled";

export const DemoDashboardNavLink: FC = () => {

    // data hooks
    const { data, isLoading } = useDashboardLink()

    // data abstraction
    const smartsheetLink = data?.dashboardLink ?? null;

    if (!smartsheetLink || isLoading) {
        return <SpanSkeleton fontSize={16} />
    }

    return (
        <NavLinkAnchor target="_blank" href={smartsheetLink}>
            Results Dashboard
        </NavLinkAnchor>
    )
}