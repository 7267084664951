import { FC, useCallback, useContext, useState } from "react";
import { ResetEnvironmentResult } from "tech-health-assessment-sdk/dist";
import { DangerButton, SecondaryButton } from "../../../../../../../../../../common/components/buttons/buttons";
import { CalloutCard } from "../../../../../../../../../../common/components/callout-card/callout-card";
import { Dialog, DialogProps } from "../../../../../../../../../../common/components/dialog/dialog";
import { DialogCard, DialogFooter, DialogHeader, DialogTitle } from "../../../../../../../../../../common/components/dialog/dialog.styled";
import { LoadingSpinner } from "../../../../../../../../../../common/components/loading-spinner/loading-spinner";
import { SnackbarContext } from "../../../../../../../../../../common/components/snackbar-provider/snackbar-provider";
import { StandardDialogInnerContainer } from "../../../../../../../../../../common/components/standard-dialog-inner-container/standard-dialog-inner-container";
import { AppColors } from "../../../../../../../../../../common/constants/colors/app-colors";
import { THAP_ENVIRONMENT } from "../../../../../../../../../../common/environment/environment-constants";
import { service } from "./reset-environment-dialog.service";

type ResetEnvironmentDialogProps = {

} & Omit<DialogProps, 'children'>

export const ResetEnvironmentDialog: FC<ResetEnvironmentDialogProps> = ({
    isOpen,
    onClose,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // event handlers
    const handleCancelButtonClicked = useCallback(() => {
        onClose()
    }, [onClose])

    const handleResetEnvironmentButtonClicked = useCallback(async () => {
        setIsLoading(true)

        let result: ResetEnvironmentResult | undefined = undefined;
        let error: unknown;
        try {
            result = await service.resetEnvironment()
        } catch (err) {
            error = err
        }

        if (!result?.success || !!error) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support',
            })
        } else {
            showSnackbar({
                type: 'success',
                title: 'Successfully reset environment'
            })
        }

        setIsLoading(false)
        onClose()
    }, [onClose, showSnackbar])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <DialogCard isOpen={isOpen}>
                <StandardDialogInnerContainer>
                    <DialogHeader>
                        <DialogTitle>
                            Reset this Environment
                        </DialogTitle>
                    </DialogHeader>
                    <CalloutCard type="error">
                        <span>
                            This will erase all of the following from this ({THAP_ENVIRONMENT}) environment
                        </span>
                        <ul>
                            <li>
                                External User Accounts (users & accounts)
                            </li>
                            <li>
                                Assessments (sections, questions, results, etc)
                            </li>
                            <li>
                                Support Requests
                            </li>
                            <li>
                                Smartsheet References (external resources that live in Smartsheet will not be deleted)
                            </li>
                        </ul>
                        <br />
                        <span>
                            We will also create a Demo lead from scratch. (username: demo@acquiretek.com)
                        </span>
                        <br />
                        <span>
                            Please proceed with caution.
                        </span>
                    </CalloutCard>
                    <DialogFooter>
                        <SecondaryButton
                            disabled={isLoading}
                            type="button"
                            onClick={handleCancelButtonClicked}
                        >
                            Cancel
                        </SecondaryButton>
                        <DangerButton
                            disabled={isLoading}
                            onClick={handleResetEnvironmentButtonClicked}
                        >
                            {isLoading && (
                                <LoadingSpinner
                                    size={14}
                                    color={AppColors.text.onDarkBackground}
                                />
                            )}
                            Reset Environment
                        </DangerButton>
                    </DialogFooter>
                </StandardDialogInnerContainer>
            </DialogCard>
        </Dialog>
    )
}