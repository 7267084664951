import { FC, useCallback } from "react";
import { PieChartIcon } from "../../../../../../../../common/components/icons/pie-chart-icon/pie-chart-icon";
import { AdminGridItem, AdminGridItemLoadingState } from "../../admin-grid-item";
import { useDashboardLink } from "./demo-dashboard-grid-item.hooks";

export const DemoDashboardGridItem: FC = () => {

    // data hooks
    const { data, isLoading } = useDashboardLink()

    // data abstraction
    const smartsheetLink = data?.dashboardLink ?? null;

    // event handlers
    const handleClick = useCallback(() => {
        window.open(smartsheetLink ?? '', '_blank')
    }, [smartsheetLink])

    if (!smartsheetLink || isLoading) {
        return <AdminGridItemLoadingState />
    }

    return (
        <AdminGridItem
            onClick={handleClick}
            title="Demo Dashboard"
            subtitle="Preview the demo dashboard"
            iconComponent={PieChartIcon}
        />
    )
}